// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~gijgo/css/gijgo';


@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/brands';

.scrollable-400 pre {
  height: 400px;
  overflow: auto;
}

.alert {
  display: inline-block;
}

.salesforce-container {
  h2 img {
    width: 50px;
  }

  .package-container {
    padding: 0 3px 0 0;
  }
}

#metatree {
  .list-group-item span[data-role="display"] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 100%;
  }
}

.meta-selector-container {
  .metacontainer {
    .metalist-parent {
      padding: 0 3px 0 0;
    }

    .metalist,
    .metaselected {
      height: 400px;
      overflow: auto;
      overflow-x: hidden;
      border: 1px solid #bbb;
      padding: 0;
    }

    .metaselected {
      height: 436px;
      border-top-left-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
    }

    .metalist .metaelement, .metaselected .metaelement {
      cursor: pointer;
      padding: 1px;
      border-bottom: 1px solid #aaa;
      padding: 3px;
    }

    .metalist .metaelement:hover, .metaselected .metaelement:hover {
      background-color: #39abf8;
    }

    .metasearch {
      padding: 0;

      input {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  button[type="submit"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.metacontainer-header .metaheader {
  padding: 0;
}